import { graphql } from 'gatsby';
import React from 'react';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CallToActionContent from '../components/UI/CallToActionContent';
import ContentHeader from '../components/UI/ContentHeader';
import SectionContainer from '../components/UI/SectionContainer';
import Casablanca from '../img/platablanca.png';
import styled from '../styles/styled-components';
import DataMarkdown from '../types/DataMarkdown';
import Image from '../types/Image';

const SectionContainerStyled = styled(SectionContainer)`
  margin-top: 48px;
  margin-bottom: 48px;
  text-align: justify;
`;
const PlataBlancaSection = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: 90px;
    width: 170px;
  }
`;

const ArticleLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

interface PropsTemplate {
  platablanca: string;
  url: string;
  title: string;
  subheading?: string;
  description: string;
  image: Image;
  content: string;
  contentComponent?: Content | HTMLContent;
}

export const BlogPostTemplate: React.FC<PropsTemplate> = ({
  title,
  subheading,
  description,
  image,
  content,
  contentComponent,
  platablanca,
  url,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      <ContentHeader
        title={title}
        subheading={subheading}
        image={
          typeof image === 'string' ? image : image.childImageSharp.fluid.src
        }
      />

      <SectionContainerStyled>
        {platablanca === 'si' && (
          <PlataBlancaSection>
            <img src={Casablanca} />
          </PlataBlancaSection>
        )}
        <PageContent content={content} />
        <PlataBlancaSection>
          {platablanca === 'si' && (
            <audio controls={true} preload="none">
              <source src={url} type="audio/mp4" />
            </audio>
          )}
        </PlataBlancaSection>
      </SectionContainerStyled>
      <CallToActionContent />
    </div>
  );
};

interface Props {
  html: string;
  frontmatter: {
    title: string;
    subheading?: string;
    description: string;
    date: string;
    tags: string;
    image: Image;
    platablanca: string;
    url: string;
  };
}

const BlogPost: React.FC<DataMarkdown<Props>> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <BlogPostTemplate
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        content={post.html}
        contentComponent={HTMLContent}
        platablanca={post.frontmatter.platablanca}
        url={post.frontmatter.url}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subheading
        description
        tags
        url
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        platablanca
        url
      }
    }
  }
`;
